import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { getRoute } from "../../routing/language_support";
import { useTranslation } from "react-i18next";
import { useSelector/*, useDispatch*/ } from "react-redux";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";
import DynamicForm from "../../component/Form/DynamicForm";
import { ATTEMPT_LOGIN } from "../../constants/constants";
//import { Api } from '../../api/Api';
//import { login, getCurrentUser } from '../../store/AuthActions';
//import { authState } from "../../store/Reducer";
import "../../i18n/config";
import { LoadingDataType } from "../../interfaces/LoadingData";
import { loadingState } from "../../store/Reducer";
import { getLoginFormStructure } from "./LoginFormStructure";
import "../../assets/scss/formElements/Checkbox.scss";
//import { TOOLBOX_FETCH_REQUESTED, TOOLBOX_RESPONSE_UPDATE } from "../../store/ToolBoxActions";
//import DocCheckLogin from "../../component/DocCheck/DocCheckLogin"; // Import the DocCheckLogin component
import DocCheckLoginModal from "../../component/DocCheck/DocCheckLoginModal"; // Import the DocCheckLogin component


interface LoginFormProps {
  onSubmit: (user: Object | undefined) => void;
}

export default function LoginForm({ onSubmit }: LoginFormProps) {
  const formStructure = getLoginFormStructure();
  const loadingData: LoadingDataType = useSelector(loadingState);
  const { userType } = useParams();
  const history = useHistory();
  //const dispatch = useDispatch();
  //const auth = useSelector(authState);
  
  //const [showDocCheck, setShowDocCheck] = useState(false);
  const [isDocCheckModalOpen, setIsDocCheckModalOpen] = useState(false);

  const isLoading = loadingData.loading.includes(ATTEMPT_LOGIN);
  const isSuccess = !!loadingData.success[ATTEMPT_LOGIN];

  const { t/*, i18n*/ } = useTranslation();
  
  // Check if the Doc Check is allowed
  const docCheckAllowed = process.env.REACT_APP_DOC_CHECK_ALLOWED === 'true'
  const iqviaAllowed = process.env.REACT_APP_IQVIA_ALLOWED === 'true'
  
  
  const triggerHcaLogin = async () => {
    if (iqviaAllowed) {
      history.push('/auth-loader?auth_type=one_key_login');
    }
  };

  return (
    <Box className={"login-left-side"}>
      <Box pt={{ md: 12, sm: 6 }} className="main-title">
        <Typography variant="h1">
          {docCheckAllowed || iqviaAllowed ? t("login.docCheck_title") : t("login.title")}
        </Typography>
      </Box>

      {userType && (
        <Typography variant="body1">
          <Box pt={5}>
            {userType === "hcp"
              ? t("login.hcp.subtitle")
              : t("login.patient.subtitle")}
          </Box>
        </Typography>
      )}

        <>
          <DynamicForm
            formStructure={formStructure}
            onSubmit={onSubmit}
            formClassName="login-form-items"
            loading={isLoading}
            success={isSuccess}
          />
          
          
          <div>
            <RouterLink to={getRoute('forgotPassword')} className="link-holder">
              {t("login.forgot_password.label")}
            </RouterLink>
            
            
            <div className={`login-page-register-container ${docCheckAllowed || iqviaAllowed ? 'doc-check-allowed' : ''}`}>
              <RouterLink to={getRoute('register')} className="register-link">
                <div className="no-account-yet">
                  {t("login.register.no_account_yet")}
                </div>
                <div className="register-text highlighted">
                  {t("login.register.register")}
                </div>
              </RouterLink>
            </div>
          </div>
        </>
        
        {docCheckAllowed && (
          <Box mt={4}>
            {/* <Typography variant="h6">{t("login.hcp.title")}</Typography> */}
            {/* <Typography>{t("login.hcp.doccheck.description")}</Typography> */}
            <Button 
              variant="text"
              color="primary"
              style={{
                backgroundColor: 'rgba(204,0,51,1)',
                color: 'white',
                width: '100%',
                '&:hover': {
                  backgroundColor: 'rgba(165,2,43,1)',
                },
              }}              
              className="submit-button doccheck-button" // Added doccheck-button class
              onClick={() => setIsDocCheckModalOpen(true)}
            >
              {t("login.fields.submit.labelDocCheck")}
            </Button>
            <DocCheckLoginModal 
              open={isDocCheckModalOpen} 
              onClose={() => setIsDocCheckModalOpen(false)} 
            />              
          </Box>
        )} 
        
        {iqviaAllowed && (
          <Box mt={4}>
            {/* <Typography variant="h6">{t("login.hcp.title")}</Typography> */}
            {/* <Typography>{t("login.hcp.doccheck.description")}</Typography> */}
            <Button 
              variant="text"
              color="primary"
              style={{
                backgroundColor: 'rgba(20,53,85,1)',
                color: 'white',
                width: '100%',
                '&:hover': {
                  backgroundColor: 'rgba(19,49,79,1)',
                },
              }}              
              className="submit-button iqvia-button" // Added doccheck-button class
              onClick={() => triggerHcaLogin()}
            >
              {t("login.fields.submit.labelOneKey")}
            </Button>
          </Box>
        )}          

    </Box>
  );
}