import React, { useEffect } from 'react';

interface WindowWithMarker extends Window {
  markerConfig?: {
    project: string;
    source: string;
  };
  Marker?: any;
}

const MarkerIO: React.FC = () => {
  useEffect(() => {
    // This effect will run once when the component mounts
    const markerProjectCode = process.env.REACT_APP_MARKER_PROJECT_CODE;

    if (markerProjectCode) {
      // Set up the markerConfig
      (window as WindowWithMarker).markerConfig = {
        project: markerProjectCode,
        source: 'snippet'
      };

      // Create and append the script
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://edge.marker.io/latest/shim.js';
      
      // Add the Marker.io initialization code
      script.innerHTML = `
        !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;}}(window,document);
      `;

      document.body.appendChild(script);
    }

    // Cleanup function to remove the script when component unmounts
    return () => {
      const script = document.querySelector('script[src="https://edge.marker.io/latest/shim.js"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return null; // This component doesn't render anything
};

export default MarkerIO;