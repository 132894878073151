import "../../assets/scss/Footer/Footer.scss";
import { LinkType } from "../../interfaces/LinkType";
import logo from "../../resources/logo/fresenius-kabi.png";
import { generateFooterLinks } from "../../util/util";

export interface DynamicFooterType {
  disclaimer_normal: string;
  disclaimer_situational: string;
  disclaimer_situational_vis: boolean;
  date: string;
  links: Array<LinkType>;
  legal_number: string;
  preparation_date: string;
  show_situational: boolean;
  isAuthenticated?: boolean;
  userRoles?: string[];
  envSettings?: {
    alwaysShow?: boolean;
    showIdacio?: boolean;
    showTyenne?: boolean;
    showHcp?: boolean;
    showNonAuth?: boolean;
  };  
}

export default function DynamicFooter({
  disclaimer_normal = "",
  disclaimer_situational = "",
  disclaimer_situational_vis = false,
  date = "",
  links = [],
  legal_number = "",
  preparation_date = "",
  show_situational = false,
  isAuthenticated = false,
  userRoles = [],
  envSettings = {
    alwaysShow: false,
    showIdacio: false,
    showTyenne: false,
    showHcp: false,
    showNonAuth: false,    
  },
}: DynamicFooterType) {
  const showSituationalDisclaimer = 
    show_situational === true ||
    disclaimer_situational_vis === true ||
    envSettings?.alwaysShow ||
    (envSettings?.showIdacio && userRoles?.includes("idacio")) ||
    (envSettings?.showTyenne && userRoles?.includes("tyenne")) ||
    (envSettings?.showHcp && userRoles?.includes("hcp")) ||
    (envSettings?.showNonAuth && !isAuthenticated);  
  
  
  return (
    <div className={"footer"}>
      <div className="footer-inner-container">
        {showSituationalDisclaimer && (
          <div
            className={"disclaimer situational"}
            dangerouslySetInnerHTML={{
              __html: disclaimer_situational,
            }}
          />
        )}
        <div
          className={"disclaimer"}
          dangerouslySetInnerHTML={{
            __html: disclaimer_normal,
          }}
        ></div>
        <div className={"bottom"}>
          <div className={"logo"}>
            <img src={logo} alt="Logo" width="238" height="64" />
          </div>
          <div className={"content"}>
            <div className="preparation-container">
              <div className={"date"}>
                <p>{preparation_date}</p>
              </div>
              <div className="legal-number">{legal_number}</div>
            </div>
            <div className={"links"}>{generateFooterLinks(links)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
