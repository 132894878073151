import React, {createRef, useEffect, useState} from "react";
import "../../assets/scss/Common/Common.scss";
import {CarouselItem} from "../../interfaces/Pages";
import {Link} from "react-router-dom";

interface LowerHomePageSectionProps {
	carouselCards: Array<CarouselItem>;
	inactiveCard: CarouselItem;
	setHeroContent: (content: CarouselItem) => void;
	setInactiveCard: (item: CarouselItem) => void;
	setCarouselCards: (items: Array<CarouselItem>) => void;
}

export const LowerHomePageSection = ({
										 carouselCards,
										 inactiveCard,
										 setHeroContent,
										 setInactiveCard,
										 setCarouselCards,
									 }: LowerHomePageSectionProps) => {
	const [itemRefs, setItemRefs] = useState<Array<any>>([]);

	useEffect(() => {
		setItemRefs([...Array(12).keys()].map((i) => createRef()));
	}, []);

	/*
	const swapHeroImage = (item: CarouselItem) => {
		setHeroContent(item);
		handleCarouselSelect(item);
	};

	const handleCarouselSelect = (item: CarouselItem) => {
		const newCarouselCards = carouselCards.filter(
			(card) => item.nid !== card.nid
		);

		setCarouselCards([...newCarouselCards, inactiveCard]);

		setInactiveCard(item);
	};
	*/

	return (
		<div className="lower-section">
			<div className="home-page-carousel">
				{carouselCards.map((item, i) => {
					const url = item.field_hp_carousel_item_link.uri.replace("internal:", "");
					return (
						url && (
							<div
								ref={itemRefs[i]}
								className={"carousel-item"}
								key={i}
								// onClick={() => swapHeroImage(item)}
							>
								<Link to={url}>
									<picture>
										<source
											media="(min-width:768px)"
											srcSet={item.field_homepage_carousel_item_img.url}
										/>
										<source srcSet={item.field_hp_carousel_item_img_small?.url}/>
										<img
											src={item.field_hp_carousel_item_img_small?.url}
											alt={item.field_homepage_carousel_item_img.alt}
											height="335"
											width="158"
										/>
									</picture>

									<div className="text">
										<h2><span dangerouslySetInnerHTML={{ __html: item.title }} /></h2>
									</div>
								</Link>
							</div>
						))
				})}
			</div>
		</div>
	);
};
