import React from "react";
import { AuthLink } from "../../routing/AuthLink";
import { noop, sanitizeURL } from "../../util/util";

                       
const MenuItem = ({ url, roles, label, field_include_triangle  }) => {
  
  const showTriangle = field_include_triangle !== undefined && 
                         (field_include_triangle === true || field_include_triangle === "1");
                       
  return (
    <div className="menu-item">
      <AuthLink to={sanitizeURL(url)} roles={roles} onClose={noop} className={showTriangle ? "has-triangle" : ""}>
        {label}
      </AuthLink>
    </div>
  );
};

export default MenuItem;
