import React from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRoute } from "../../routing/language_support";
import RegisterAsHCPImage from "../../resources/images/auth/register_hcp_image.png";

const RegisterButton = ({ docCheckAllowed, iqviaAllowed, onHcaRegister }) => {
  const { t } = useTranslation();

  const commonProps = {
    variant: "body2",
    underline: "none",
    className: "hcp-register-button register-button"
  };

  let linkProps = {};

  if (iqviaAllowed) {
    linkProps = {
      onClick: (e) => {
        e.preventDefault();
        onHcaRegister();
      },
    };
  } else if (docCheckAllowed) {
    linkProps = {
      href: "https://www.doccheck.com/register?auth_type=doccheck",
      target: "_blank",
      rel: "noopener noreferrer"
    };
  } else {
    linkProps = {
      component: RouterLink,
      to: getRoute('hcpRegister')
    };
  }

  return (
    <MuiLink 
      {...commonProps} 
      {...linkProps} 
      style={{ cursor: 'pointer', ...(commonProps.style || {}) }}
    >
      <div
        className="button-content-container"
        aria-label={t("register.form.common.hcpTile.label")}
      >
        <img
          src={RegisterAsHCPImage}
          alt=""
          className="button-image"
        />
        <div className="button-label">
          {t("register.form.common.hcpTile.label")}
        </div>
      </div>
    </MuiLink>
  );
};

export default RegisterButton;