import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Condition } from "../../interfaces/Condition";
import { PageType } from "../../interfaces/Pages";
import { authState, loadingState } from "../../store/Reducer";
import { getRoute } from "../../routing/language_support";

export const YourConditionPage = () => {
  const conditions: Condition[] = useSelector(authState).conditions || [];
  const user = useSelector(authState).user;
  const loading = useSelector(loadingState)?.loading;
  const history = useHistory();
  const baseUrl = getRoute('yourCondition');

  if (
    !loading.includes(PageType.yourCondition) &&
    user &&
    conditions.length !== 0 &&
    !user?.condition
  ) {
    history.push(baseUrl + '/' + conditions[0].condition);
  } else if (user && user.condition && user.condition !== "") {
    history.push(
      baseUrl + '/' +
        user.condition
          .toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll("&#039;", "'")
    );
  }

  return <></>;
};
