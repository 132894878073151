import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { getRoute } from "../routing/language_support";
import { Api } from "../api/Api";
import { AuthContext } from "../context/AuthContext";
import { initialLoadingData, LoadingDataType } from "../interfaces/LoadingData";
import { ToolBoxData, ToolBoxDataType } from "../interfaces/ToolBoxData";
import { omitKey } from "../util/util";
import {
  AuthActionTypes,
  LOGIN,
  LOGOUT,
  UPDATE_CONDITIONS,
  UPDATE_USER,
} from "./AuthActions";
import {
  ContentActions,
  UPDATE_ATTACHMENT,
  UPDATE_CONTENT,
  UPDATE_CONTENTS,
  UPDATE_SECTION,
} from "./PageContentActions";
import mySaga from "./Sagas";
import {
  CLEAR_FORM_ERRORS,
  LoadingAction,
  LOADING_FINISHED,
  LOADING_REMOVE_ALL_SUCCESS,
  LOADING_REMOVE_SUCCESS,
  LOADING_STARTED,
  ToolBoxAction,
  TOOLBOX_CREATE,
  TOOLBOX_DELETE,
  TOOLBOX_EDIT,
  TOOLBOX_RESPONSE_UPDATE,
} from "./ToolBoxActions";

const initialAuthState: AuthContext = {
  authenticated: false,
  logoutToken: undefined,
  token: undefined,
  user: {
    roles: "",
  },
  tokenType: undefined,
  accessToken: undefined,
  expiresIn: undefined,
  refreshToken: undefined,
  msg: undefined,
  roles: [""],
};

const initialDataState: ToolBoxData = {
  goals: [],
  medications: [],
  error: "",
  msg: "",
  formErrors: null,
  conditions: [],
};

export function authReducer(
  state = initialAuthState,
  { type, userData, msg, conditions }: AuthActionTypes
): AuthContext {
  switch (type) {
    case LOGIN: {
      localStorage.setItem("token", JSON.stringify({ ...userData }));

      return {
        ...state,
        authenticated: true,
        token: userData?.csrf_token,
        logoutToken: userData?.logout_token,
        tokenType: userData?.token_type,
        expiresIn: userData?.expires_in,
        accessToken: userData?.access_token,
        refreshToken: userData?.refresh_token,
        roles: userData?.roles || [""],
        email: userData?.email,
      };
    }

    case LOGOUT: {
      Api.logout();
      localStorage.removeItem("token");

      createBrowserHistory().push(getRoute('login'));
      window.location.reload();

      return { msg: msg, ...initialAuthState };
    }

    case UPDATE_USER: {
      const stateSaved = JSON.parse(localStorage.getItem("token") || "");

      localStorage.setItem(
        "token",
        JSON.stringify(
          Object.assign(stateSaved, { ...state.user, ...userData })
        )
      );

      return {
        ...state,
        user: { ...state.user, ...userData },
        roles: userData?.roles,
      };
    }

    case UPDATE_CONDITIONS: {
      return {
        ...state,
        conditions: conditions,
      };
    }

    default:
      return state;
  }
}

export function toolBoxReducer(
  state = initialDataState,
  {
    type,
    data,
    msg,
    error,
    formErrors,
    dataType = ToolBoxDataType.MEDICATIONS,
  }: ToolBoxAction
): ToolBoxData {
  let newData = Array<any>();

  switch (type) {
    case TOOLBOX_RESPONSE_UPDATE:
      return {
        ...state,
        msg: msg,
        error: error,
        formErrors: formErrors,
      };

    case CLEAR_FORM_ERRORS:
      return {
        ...state,
        formErrors: null,
        msg: "",
        error: "",
      };

    case TOOLBOX_EDIT:
      newData = (state[dataType] as Array<any>).map((s) =>
        parseInt(s.id) === parseInt(data.id) ? data : s
      );

      return {
        ...state,
        [dataType]: newData,
      };

    case TOOLBOX_DELETE:
      newData = (state[dataType] as Array<any>).filter((s) => {
        return s.id.toString() !== data.toString();
      });

      return {
        ...state,
        [dataType]: newData,
      };

    case TOOLBOX_CREATE:
      data.id = parseInt(data.id);

      return {
        ...state,
        [dataType]: [...state[dataType], data],
      };

    default:
      return {
        ...state,
        ...data,
      };
  }
}

export function loadingReducer(
  state = initialLoadingData,
  { type, page, msg, success }: LoadingAction
): LoadingDataType {
  switch (type) {
    case LOADING_STARTED: {
      return {
        ...state,
        loading: [...state.loading, page],
      };
    }
    case LOADING_FINISHED: {
      let obj = {};

      if (success) {
        obj = {
          success: {
            ...state.success,
            [page]: {
              msg: msg,
            },
          },
          error: omitKey(state.error, page),
        };
      } else {
        obj = {
          error: {
            ...state.error,
            [page]: {
              msg: msg,
            },
          },
          success: omitKey(state.success, page),
        };
      }

      return {
        ...state,
        loading: [...state.loading.filter((p) => p !== page)],
        ...obj,
      };
    }

    case LOADING_REMOVE_SUCCESS: {
      return {
        ...state,
        success: omitKey(state.success, page),
      };
    }

    case LOADING_REMOVE_ALL_SUCCESS: {
      return {
        ...state,
        success: {},
      };
    }

    default:
      return state;
  }
}

export function contentReducer(
  state = {},
  { type, page, content, section, sectionNumber, pages }: ContentActions
): Object {
  switch (type) {
    case UPDATE_CONTENT: {
      return {
        ...state,
        [page]: {
          ...state[page],
          ...content,
        },
      };
    }
    case UPDATE_CONTENTS: {
      let pageContents = {};

      pages?.forEach((page) => {
        pageContents[page] = {
          ...state[page],
          ...content,
        };
      });

      return {
        ...state,
        ...pageContents,
      };
    }
    case UPDATE_SECTION: {
      return {
        ...state,
        [page]: {
          ...state[page],
          sections: state[page].sections.map((oldSection, number) =>
            number === sectionNumber ? section : oldSection
          ),
        },
      };
    }
    case UPDATE_ATTACHMENT: {
      return {
        ...state,
        [page]: {
          ...state[page],
          attachments: content,
        },
      };
    }
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  toolbox: toolBoxReducer,
  content: contentReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(mySaga);

export type RootState = ReturnType<typeof rootReducer>;

export const authState = (state: RootState) => state.auth;
export const toolBoxState = (state: RootState) => state.toolbox;
export const loadingState = (state: RootState) => state.loading;
export const contentState = (state: RootState) => state.content;
