import { RouteConfig } from './types';

export const deRoutes: RouteConfig = {
  home: '/home',
  loginWithType: '/anmeldung/:userType', // Change
  login: '/anmeldung',
  forgotPassword: '/passwort-vergessen', // Change
  changePassword: '/change-password', // Change
  register: '/registrieren', // Change
  patientRegister: '/patient/registrieren', // Change
  patientThankYou: '/patient/danke', // Change
  hcpRegister: '/hcp/registrieren', // Change
  hcpThankYou: '/hcp/danke', // Change
  profile: '/profil', // Change
  yourCondition: '/erste-schritte/ihre-erkrankung', // Change
  coachingTool: '/wohlbefinden-und-unterstützung/ihr-wohlbefinden/coaching-instrumente', // Change
  
  hcpMakeRequestRepCall: '/hcp-zugang/anfrage/fordern-sie-einen-vertreteranruf-an', // Change
  hcpRequestRepCall: '/hcp-zugang/fordern-sie-einen-vertreteranruf-an', // Change
  hcpOrderKitIdacio: '/hcp-zugang/anfrage/bestellen-sie-ein-kit-idacio', // Change
  hcpOrderKitTyenne: '/hcp-zugang/anfrage/bestellen-sie-ein-kit-tyenne', // Change
  dynamic: '',
  hcpAccess: '/hcp-zugang', // Change
  myTreatment: '/meine-behandlung',
  
  idacioUnderstanding: '/mein-medikament/understanding-treatment',
  idacioHowToInject: '/mein-medikament/how-to-inject-idacio',
  idacioCaring: '/mein-medikament/caring-for-your-medication',
  idacioHelp: '/mein-medikament/help-and-support',
  idacioGoalsSettings: '/mein-medikament/mein-fortschritt/ziele/einstellungen',
  idacioGoals: '/mein-medikament/mein-fortschritt/ziele',
  idacioGoalsSettingsGoal: '/mein-medikament/mein-fortschritt/ziele/einstellungen/ziel',
  idacioLogMedicationSettings: '/mein-medikament/mein-fortschritt/medikamenten-verfolgung/einstellungen',
  idacioLogMedicationSettingsMedication: '/mein-medikament/mein-fortschritt/medikamenten-verfolgung/einstellungen/medikamenten',
  idacioReminders: '/mein-medikament/mein-fortschritt/erinnerungen',
  idacioProgress: '/mein-medikament/mein-fortschritt',
  idacioCoolbagThankYou: '/mein-medikament/coolbag/danke',
  idacioCoolbag: '/mein-medikament/coolbag',
  idacio: '/mein-medikament',
  
  tyenneUnderstanding: '/mein-tyenne/understanding-tyenne',
  tyenneHowToInject: '/mein-tyenne/how-to-inject-tyenne',
  tyenneCaring: '/mein-tyenne/caring-for-your-tyenne',
  tyenneHelp: '/mein-tyenne/help-and-supports',
  tyenneGoals: '/mein-tyenne/mein-fortschritt/ziele',
  tyenneGoalsSettings: '/mein-tyenne/mein-fortschritt/ziele/einstellungen',
  tyenneGoalsSettingsGoal: '/mein-tyenne/mein-fortschritt/ziele/einstellungen/ziel',
  tyenneLogMedication: '/mein-tyenne/mein-fortschritt/medikamenten-verfolgung/einstellungen',
  tyenneLogMedicationSettingsMedication: '/mein-tyenne/mein-fortschritt/medikamenten-verfolgung/einstellungen/medikamenten',
  tyenneReminders: '/mein-tyenne/mein-fortschritt/erinnerungen',
  tyenneProgress: '/mein-tyenne/mein-fortschritt',
  tyenneCoolbagThankYou: '/mein-tyenne/request-a-coolbag/danke',
  tyenneCoolbag: '/mein-tyenne/request-a-coolbag',
  tyenne: '/mein-tyenne',
};