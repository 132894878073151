// AuthLoadingPage.tsx

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getRoute } from "../../routing/language_support";
import { PageType } from '../../interfaces/Pages';
import WithLoader from '../../component/WithLoader';
import { loading } from '../../store/ToolBoxActions';
import { setUpHcaSdk } from "../../resources/scripts/HcaScript";
import { AuthHandler } from '../../util/AuthHandler';


const AuthLoadingPage: React.FC = () => {
  const [sdkStatus, setSdkStatus] = useState<'loading' | 'ready' | 'error'>('loading');
  const [/*authResult*/, setAuthResult] = useState<string | null>(null);
  const location = useLocation();
  const docCheckAllowed = process.env.REACT_APP_DOC_CHECK_ALLOWED === 'true';
  const iqviaAllowed = process.env.REACT_APP_IQVIA_ALLOWED === 'true';
  const history = useHistory();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(loading(PageType.general));
    
    const initSdk = async () => {
      if (iqviaAllowed) {
        try {
          await setUpHcaSdk();
          setSdkStatus('ready');
        } catch (error) {
          setSdkStatus('error');
        }
      } else {
        setSdkStatus('ready');
      }
    };
    initSdk();
  }, [iqviaAllowed, dispatch]);


  useEffect(() => {
    let timer: NodeJS.Timeout;

    const handleAuth = async () => {
      const params = new URLSearchParams(location.search);
      const authType = params.get('auth_type')
      const authState = localStorage.getItem('authState');
      
      // Initial returns if conditions matched
      
      if (!docCheckAllowed && !iqviaAllowed) {
        history.push(getRoute('login'));
        return;
      } 
      
      const validAuthTypes = ['one_key_login', 'one_key_register', 'doccheck'];
      if ((!authState || authState !== 'in_progress') && 
          (!authType || !validAuthTypes.includes(authType))) {
        history.push(getRoute('login'));
        return;
      }
      
      // Check whether we meet the conditions of either a docCheck or Iqvia redirect
      const isDocCheckRedirect = params.get('uniquekey') !== null && authType === "doccheck"
      const isIqviaRedirect = authState === 'in_progress'
      
      const handleSuccessfulAuth = () => {
        setAuthResult('Authentication completed');
        history.push('/'); // Redirect to home page after successful authentication
      };      
      
      // If redirect from docCheck then authenticate
      if (isDocCheckRedirect) {
        setAuthResult('Completing DocCheck authentication...');
        
        const uniqueKey = params.get('uniquekey');
        if (uniqueKey) {
          const email = `${uniqueKey}@kabicare.anonymous.com`;
          try {
            // New: Use AuthHandler and handle successful authentication
            await AuthHandler.handleAuthentication(email);
            handleSuccessfulAuth();
            return true;
          } catch (error) {
            setAuthResult(`DocCheck authentication failed: ${error.message}`);
          }
        } else {
          setAuthResult('DocCheck authentication failed: unique_id not found');
          return false;
        }
      }
      
      // If redirect from iqvia then authenticate
      if (isIqviaRedirect) {
        setAuthResult('Completing IQVIA authentication...');
    
        const checkIqviaStorage = async () => {
          const iqviaStorageKey = Object.keys(localStorage).find(key => 
            key.includes('-b2c_1a_hca_signup_signin') && 
            key.includes('-auth.onekeyconnect.com-') &&
            !key.includes('idtoken') &&
            !key.includes('accesstoken') &&
            !key.includes('refreshtoken')
          );
    
          if (iqviaStorageKey) {
            try {
              const iqviaData = JSON.parse(localStorage.getItem(iqviaStorageKey) || '{}');
              if (iqviaData.idTokenClaims && iqviaData.idTokenClaims.email) {
                // New: Use AuthHandler and handle successful authentication
                await AuthHandler.handleAuthentication(iqviaData.idTokenClaims.email);
                handleSuccessfulAuth();
                return true;
              }
            } catch (error) {
              return false;
            }
          }
          
          return false;
        };
    
        let attempts = 0;
        const maxAttempts = 10;
        const attemptAuthentication = async () => {
          const result = await checkIqviaStorage();

          if (result) {
            setAuthResult('IQVIA authentication completed');
            return true;
          } else if (++attempts >= maxAttempts) {
            setAuthResult('IQVIA authentication failed: email not found');
            localStorage.removeItem('authState');
            history.push(getRoute('login'));
            return true;
          }
      
          return false;
        };
      
        const intervalId = setInterval(async () => {
          const shouldStop = await attemptAuthentication();
          if (shouldStop) {
            clearInterval(intervalId);
          }
        }, 500);
      }

      
      // Initiate either Iqvia login or registration
      if (!authState || authState === 'initiating') {
        localStorage.setItem('authState', 'initiating');
        try {
          
          if (!iqviaAllowed) {
            throw new Error('IQVIA (OneKey) authentication is not allowed');
          }
          
          if (typeof window.hcaSdk === 'undefined') {
            throw new Error('HCA SDK not available');
          }
      
          switch (authType) {
            case 'one_key_login':
              if (typeof window.hcaSdk.signIn !== 'function') {
                throw new Error('signIn method not available');
              }
              setAuthResult('Signing in...');
              await window.hcaSdk.signIn();
              setAuthResult('Sign in initiated!');
              break;
            case 'one_key_register':
              if (typeof window.hcaSdk.signUp !== 'function') {
                throw new Error('signUp method not available');
              }
              setAuthResult('Signing up...');
              await window.hcaSdk.signUp();
              setAuthResult('Sign up initiated!');
              break;
            default:
              throw new Error('Invalid auth type for OneKey authentication');
          }
          
          localStorage.setItem('authState', 'in_progress');
        } catch (error) {
          setAuthResult(`Authentication failed: ${error.message}`);
          localStorage.removeItem('authState');
        }
      }
    };
  
    // If sdk status is "ready" or if docCheck is allowed and Iqvia is not allowed set timer to authenticate after 3 seconds
    if ((!iqviaAllowed && docCheckAllowed) || sdkStatus === 'ready') {
      timer = setTimeout(() => {
        handleAuth();
      }, 2000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    
  }, [sdkStatus, location, iqviaAllowed, docCheckAllowed, history]);

  return (
    <WithLoader controlLoading={true} loading={true}>
      <div>
      </div>
    </WithLoader>
  );
  
};

export default AuthLoadingPage;