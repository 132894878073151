import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import CookieSection from "./component/Cookies/Cookies";
import Navbar from "./component/Nav/Nav";
import ScrollToTop from "./component/ScrollToTop";
import WithLoader from "./component/WithLoader";
import { getUserData } from "./context/AuthContext";
import { PageType } from "./interfaces/Pages";
import { Routes } from "./routing/Routes";
import { getConditions, getCurrentUser, LOGIN } from "./store/AuthActions";
import { getContent } from "./store/PageContentActions";
import { authState } from "./store/Reducer";
import { TOOLBOX_FETCH_REQUESTED } from "./store/ToolBoxActions";
import ReactGA from "react-ga";
import MarkerIO from './component/MarkerIO';
import "./i18n/config";

const App = () => {
  const auth = useSelector(authState);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(!localStorage.getItem("cookie_accept"));

  const submitCookie = () => {
    localStorage.setItem("cookie_accept", "true");

    const windowGlobal = (window as any)
  
    windowGlobal.gtag('config', process.env.REACT_APP_GTAG);

    setOpen(false);
  };

  useEffect(() => {
    ReactGA.initialize("G-ZQWQ8LH72D");
    ReactGA.pageview(window.location.pathname + window.location.search);

    const userData = getUserData();
    if (userData.access_token && auth.authenticated === false) {
      dispatch(getContent(PageType.profile));
      dispatch({ type: LOGIN, userData: userData });
      dispatch({ type: TOOLBOX_FETCH_REQUESTED });
    }

    dispatch(getContent(PageType.homeOld));
    dispatch(getConditions());
    dispatch(getCurrentUser());
    dispatch(getContent(PageType.popup));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      <Router>
        <ScrollToTop />
        <MarkerIO />
        <div className={"page-container"}>
          <Navbar />
          <div className={"content-wrap"}>
            <WithLoader>
              <CookieSection
                open={open}
                onSubmit={submitCookie}
                toggleDrawer={(open) => setOpen(open)}
              />
              <Routes />
            </WithLoader>
          </div>
        </div>
      </Router>
    </Suspense>
  );
};

export default App;
