import { DynamicFooterType } from "../component/Footer/DynamicFooter";
import { ProfilePageType } from "./AccountPageType";
import { FooterContentType } from "./FooterType";
import { HomePageType } from "./HomePageType";
import { NavMenu } from "./NavMenu";
import { RestrictedPopupType } from "./RestrictedPopupType";
import { SectionType } from "./SectionType";

const language = process.env.REACT_APP_LANGUAGE || 'en';


export enum PageType {
  none = "",
  login = language === 'en' ? 'login' :
          language === 'de' ? 'anmeldung' : 'login',  
  registerPatient = "register-patient",
  registerHCP = "register-hcp",
  home = language === 'en' ? 'home' :
         language === 'de' ? 'home' : 'home',    
  homeOld = "homepage",
  profile = language === 'en' ? 'profile' :
            language === 'de' ? 'profil' : 'profile',   
  popup = "restricted-popup",
  gettingStarted = "getting-started",
  nutrition = "nutrition",
  eatingWell = "eating-well",
  coachingTool = language === 'en' ? 'wellbeing-and-support/your-wellbeing/coaching-tool' :
                 language === 'de' ? 'wohlbefinden-und-unterstützung/ihr-wohlbefinden/coaching-instrumente' : 'wellbeing-and-support/your-wellbeing/coaching-tool',
  yourCondition = language === 'en' ? 'patient/your-condition' :
                  language === 'de' ? 'patient/ihre-erkrankung' : 'patient/your-condition',   
  coolbagThankYou = "coolbag-thank-you",
  general = "general",
  form = "form",
  flareForm = "flare-form",
  symptomTrackerForm = "symptom-tracker-form",
  coolbag = "coolbag",
  policy = "policies",
  myProgress = "my-progress",
  symptomTracker = "my-progress/symptom-tracker",
  symptomTrackerSettings = "my-progress/symptom-tracker/settings",
  symptomTrackerEditSymptom = "my-progress/symptom-tracker/settings/symptom",
  symptomTrackerCreateSymptom = "my-progress/symptom-tracker/settings/symptom/create",
  medicationLogger = "my-progress/log-medication",
  medicationLoggerSettings = "my-progress/log-medication/settings",
  medicationLoggerEditMedication = "my-progress/log-medication/settings/medication",
  medicationLoggerCreateMedication = "my-progress/log-medication/settings/medication/create",
  medicationLoggerForm = "medication-logger-form",
  goalSettings = "my-progress/goals/settings",
  goalEdit = "my-progress/goals/settings/goal",
  goalCreate = "my-progress/goals/settings/goal/create",
  goalCreateCustom = "my-progress/goals/settings/goal/create/custom",
  appointmentSettings = "my-progress/appointments/settings",
  appointmentEdit = "my-progress/appointments/settings/appointment",
  appointmentCreate = "my-progress/appointments/settings/appointment/create",
  noteSettings = "my-progress/notes/settings",
  noteEdit = "my-progress/notes/settings/note",
  noteCreate = "my-progress/notes/settings/note/create",
  toolbox = "toolbox",
  reminder = "my-progress/reminders/settings",
  notFound = "not-found",
  forgotPassword = "forgot-password",
  changePassword = "change-password",
  orderKit = "/hcp-access/make-a-request/order-a-kit",
  error = "error",
}

export interface PageContent {
  breadcrumbs?: Array<BreadcrumbItem>;
  menu: NavMenu;
  footer: FooterContentType;
  title?: string;
  subtitle?: string;
}

export interface Link {
  uri: string;
  title: string;
}

export interface Image {
  title: string;
  alt: string;
  url: string;
  target_id: string;
}

export interface CoachingModule {
  body: string;
  field_course_content_path: string;
  field_title: string;
  field_subtitle: string;
}

export interface CarouselItem {
  nid: number;
  body: string;
  title: string;
  description: string;
  field_homepage_carousel_item_img: Image;
  field_hp_carousel_item_img_small: Image;
  field_hp_carousel_item_link: { title: string; uri: string };
  field_hero_image: Image;
  field_hero_image_medium: Image;
  field_hero_image_small: Image;
  field_video_url: { title: string; uri: string };
}

export interface SymptomGalleryItem {
  field_symptom_gallery_item_image: Image;
  field_articulate_360?: CoachingModule;
  field_link: string;
  title: string;
  field_url: string;
  field_sgalleryi_alternative_text: string;
}

export interface ColumnItem {
  field_title?: string;
  field_subtitle?: string;
  field_image?: Image;
  body?: string;
}

export interface SectionContent {
  body: string;
  subtitle: string;
  title: string;
  link: string;
  title2: string;
  subtitle2: string;
  link2: string;
  body2: string;
  type: SectionType;
  image: Image;
  images?: Array<Image>;
  carousel_items: Array<CarouselItem>;
  gallery_items: Array<SymptomGalleryItem>;
  column_items: Array<SymptomGalleryItem>;
  image_alignment: string;
  image_height: number;
  title3: string;
  body3: string;
}

export interface ToolBoxModalContent {
  delete_button_desktop: string;
  delete_button_mobile?: string;
  cancel_button: string;
  content?: string;
  img?: string;
}

export interface ActionPageContent extends PageContent {
  delete_button?: string;
  cancel_button?: string;
  save_button?: string;
  modal?: ToolBoxModalContent;
  modal_goal_completed?: ToolBoxModalContent;
  legal_number: string;
}

interface BreadcrumbItem {
  label: string;
  url: string | null;
}

export interface LoginPage extends PageContent {
  //login_page_login_section_title: string;
  //login_page_login_section_email_placeholder: string;
  //login_page_login_section_password_placeholder: string;
  //login_page_login_section_forgot_email_label: string;
  //login_page_login_section_forgot_password_label: string;
  //login_page_login_section_submit_button_label: string;
  //login_page_login_section_hcp_restricted_message: string;
  //login_page_login_section_patient_restricted_message: string;
  //login_page_register_section_title: string;
  //login_page_register_section_content: string;
  //login_page_register_section_patient_register_button_label: string;
  //login_page_register_section_hcp_register_button_label: string;
  //login_page_register_section_description: string;
  //login_page_patient_restricted_register_section_content: string;
  //login_page_hcp_restricted_register_section_content: string;
}

export interface RegisterPatientPage extends PageContent {
  /*register_patient_page_title: string;
  register_patient_page_email: string;
  register_patient_page_condition: string;
  register_patient_page_access_code: string;
  register_patient_page_password: string;
  register_patient_page_confirm_password: string;
  register_patient_page_checkbox_label: string;
  register_patient_page_submit_button_label: string;*/
}

export interface RegisterHCPPage extends PageContent {
  /*register_hcp_page_title: string;
  register_hcp_page_user_title: string;
  register_hcp_page_first_name: string;
  register_hcp_page_last_name: string;
  register_hcp_page_country: string;
  register_hcp_page_healthcare_organization: string;
  register_hcp_page_email: string;
  register_hcp_page_registration_number_label: string;
  register_hcp_page_password: string;
  register_hcp_page_confirm_password: string;
  register_hcp_page_checkbox_label: string;
  register_hcp_page_submit_button_label: string;*/
  register_hcp_page_select_field_titles: {
    [label: string]: {
      value: string;
      label: string;
    };
  };
}

export interface GettingStartedPage extends PageContent {
  getting_started_page_title: string;
  getting_started_page_description: string;
  carousel: Array<Object>;
}

export interface NutritionPage extends PageContent {
  getting_started_page_title: string;
  getting_started_page_description: string;
  carousel: Array<Object>;
}

export interface EatingWellPage extends PageContent {
  eating_well_page_title: string;
  carousel: Array<Object>;
  cta: Object;
  card: Object;
  eating_well_page_section_1: Object;
  eating_well_page_section_2: Object;
  eating_well_page_section_3: Object;
  references: string;
  symptom_gallery: Array<Object>;
  video: Object;
}

export interface CoolbagThankYouPage extends PageContent {
  thank_you_coolbag_page_title: string;
  thank_you_message: string;
}

export interface CoolbagPage extends PageContent {
  content: string;
  canOrder: boolean;
}

export interface StandardPage {
  title: string;
  content: string;
}

export interface CachePolicyContent {
  content: string;
  cancel_button: string;
  submit_button: string;
}

export interface PrivacyPolicyPage extends PageContent {
  cache_policy: CachePolicyContent;
  privacy_policy: StandardPage;
}

export interface SymptomTrackerPageType extends PageContent {
  flare_question: string;
  flare_cancel_button: string;
  flare_submit_button: string;
  flare_yes_button: string;
  flare_thank_you_msg: string;
  save_button: string;
}

export interface ToolBoxSettingsPageType extends PageContent {
  add_label: string;
  tab_labels?: Array<string>;
  see_more?: string;
  legal_number: string;
}

export interface SymptomEditPageType extends ActionPageContent {
  label: string;
}

export interface MedicationEditPageType extends ActionPageContent {
  name: string;
  frequency: string;
  starting_date: string;
  time: string;
}

export interface GoalEditPageType extends ActionPageContent {
  name: string;
  target_date: string;
  time: string;
  completion: string;
  suggested_goal: Array<any>;
  placeholder: string;
}

export interface AppointmentEditPageType extends ActionPageContent {
  name: string;
  description: string;
  date: string;
  time: string;
}

export interface NoteEditPageType extends ActionPageContent {
  body: string;
}

export interface LogMedicationPageType extends ActionPageContent {
  select_label: string;
  date_label: string;
  time_label: string;
}

export interface GoalCreationPageType extends PageContent {
  suggested_goals: Array<string>;
  create_your_own: string;
  custom_goal_button: string;
  custom_goal: string;
  legal_number: string;
  placeholder: string;
}

export interface ReminderPageType extends ActionPageContent {
  switch_label: string;
  frequency: string;
  tab_labels: string[];
  day: string;
  select_label: string;
  day_labels: string[];
}

export interface ToolboxPdfContentType {
  title: string;
  body: string;
  image: Image;
  attachment: Attachment;
}

export interface MyProgressPageType extends PageContent {
  //tab_labels: string[];
  today_label: string;
  medications: {
    title: string;
    empty_msg: string;
    dialog_title: string;
    dialog_content_text: string;
  };
  goals: {
    title: string;
    empty_msg: string;
    dialog_title: string;
    dialog_content_text: string;
  };
  toolbox_buttons: {
    medications: string;
    goals: string;
    blood_results: string;
    reminder: string;
    medications_settings: string;
    further_navigation: string;
  };
  report_title: string;
  report_view: string;
  report_share: string;
  legal_number: string;
  toolbox_pdf_content: Array<ToolboxPdfContentType>;
}

export interface CoachingToolPageType extends PageContent {
  articulate_body: string;
  articulate_course_path: string;
  articulate_title: string;
  articulate_subtitle: string;
  carousel_items: Array<CarouselItem>;
  nid: string;
  section_1_body: string;
  section_1_subtitle: string;
  section_1_title: string;
  section_2_body: string;
  section_2_subtitle: string;
  section_2_title: string;
  section_3_body: string;
  section_3_subtitle: string;
  section_3_title: string;
  section_column_ref_1: ColumnItem;
  section_column_ref_2: ColumnItem;
  section_column_ref_3: ColumnItem;
  carousel_title: string;
  carousel_subtitle: string;
  references_title: string;
  references: string;
  subtitle: string;
  legal_number: string;
  field_ct_legal_number: string;
  hero_image: HeroSection;
  sections: Array<SectionContent>;
}

export interface DynamicPageType extends PageContent {
  body: string;
  nid: string;
  sections: Array<SectionContent>;
  attachments1: Array<Attachment>;
  attachments1_title: string;
  attachments1_body: any;
  attachments2: Array<Attachment>;
  attachments2_title: string;
  attachments2_body: any;
  main_page: boolean;
  legal_number: string;
  hero_image: HeroSection;
  footer_dynamic: DynamicFooterType;
}

export interface HeroSection {
  alt: string;
  height: number;
  target_id: number;
  title: string;
  url: string;
  width: number;
}

export interface Attachment {
  field_media_document: {
    description: string;
    url: string;
  };
  field_title: string;
  field_thumbnail: {
    alt: string;
    url: string;
    title: string;
  };
}

export interface YourConditionPageType extends PageContent {
  nid: string;
  section_1_body: string;
  section_1_subtitle: string;
  section_1_title: string;
  section_cta_body: string;
  section_cta_subtitle: string;
  section_cta_title: string;
  section_cta_link: string;
  references_title: string;
  references: string;
  symptom_gallery: Array<SymptomGalleryItem>;
  video: string;
}

export interface ForgotPasswordPageType extends PageContent {
  email: string;
  button: string;
  legal_number: string;
}

export interface PageContents {
  [PageType.login]: LoginPage;
  [PageType.registerPatient]: RegisterPatientPage;
  [PageType.registerHCP]: RegisterHCPPage;
  [PageType.home]: HomePageType;
  [PageType.profile]: ProfilePageType;
  [PageType.popup]: RestrictedPopupType;
  [PageType.gettingStarted]: GettingStartedPage;
  [PageType.nutrition]: NutritionPage;
  [PageType.eatingWell]: EatingWellPage;
  [PageType.coolbagThankYou]: CoolbagThankYouPage;
  [PageType.coolbag]: CoolbagPage;
  [PageType.policy]: PrivacyPolicyPage;
  [PageType.symptomTracker]: SymptomTrackerPageType;
  [PageType.symptomTrackerSettings]: ToolBoxSettingsPageType;
  [PageType.symptomTrackerEditSymptom]: SymptomEditPageType;
  [PageType.symptomTrackerCreateSymptom]: SymptomEditPageType;
  [PageType.medicationLogger]: LogMedicationPageType;
  [PageType.medicationLoggerSettings]: ToolBoxSettingsPageType;
  [PageType.medicationLoggerEditMedication]: MedicationEditPageType;
  [PageType.medicationLoggerCreateMedication]: MedicationEditPageType;
  [PageType.goalSettings]: ToolBoxSettingsPageType;
  [PageType.goalCreateCustom]: GoalEditPageType;
  [PageType.goalEdit]: GoalEditPageType;
  [PageType.goalCreate]: GoalCreationPageType;
  [PageType.appointmentSettings]: ToolBoxSettingsPageType;
  [PageType.appointmentCreate]: AppointmentEditPageType;
  [PageType.appointmentEdit]: AppointmentEditPageType;
  [PageType.noteSettings]: ToolBoxSettingsPageType;
  [PageType.noteEdit]: NoteEditPageType;
  [PageType.noteCreate]: NoteEditPageType;
  [PageType.reminder]: ReminderPageType;
  [PageType.notFound]: PageContent;
  [PageType.myProgress]: MyProgressPageType;
  [PageType.forgotPassword]: ForgotPasswordPageType;
  [PageType.error]: PageContent;
  [PageType.yourCondition]: YourConditionPageType;
}
