import { Box } from "@material-ui/core";
//import { Link as RouterLink } from "react-router-dom";

export default function ToolBoxListItem({
  text,
  number = 0,
  linkTo = "",
  showDate = false,
  showTime = false,
  date = "",
  time = "",
  shortTitle = false,
  noHashtag = false,
  hasViewIcon,
  onClick,
  isMedication = false,
}) {
  let realText = text;

  if (shortTitle && text.length > 55) {
    realText = text.substr(0, 50) + "...";
  }

  return (
    <Box className="align-center center-align text-align-left">
      <button
        onClick={onClick}
        className={`link-wrapper ${
          isMedication ? "medication-link-wrapper" : ""
        }`}
      >
        <p className={"medication-name"}>
          {isMedication ? `${number}. ${realText}` : `${realText}`}
        </p>
        {showDate && <p className="date">{date}</p>}
        {showTime && <p className="time">{time}</p>}
        {hasViewIcon && (
          <span className="link-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.008 15.619">
              <g
                data-name="Icon feather-eye"
                fill="none"
                stroke="#003565"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.25"
              >
                <path
                  data-name="Path 70"
                  d="M.625 7.81S4.217.625 10.5.625s9.879 7.185 9.879 7.185-3.589 7.184-9.879 7.184S.625 7.81.625 7.81Z"
                />
                <path
                  data-name="Path 71"
                  d="M13.198 7.809a2.694 2.694 0 1 1-2.694-2.694 2.694 2.694 0 0 1 2.694 2.694Z"
                />
              </g>
            </svg>
          </span>
        )}
      </button>
    </Box>
  );
}
