import React from "react";
import { Link as RouterLink } from "react-router-dom";

import RightArrow from "../../../resources/images/common/icon-feather-arrow-right.png";
//import { useTranslation } from "react-i18next";

const Card = ({
  target,
  imgWebp = "",
  imgSmall = "",
  img,
  imgSmallWebp = "",
  imgTitle = "",
  title,
  description,
  linkLabel = "",
  url = "",
  hasText,
  isAttachment = false,
  videoUrl = "",
}) => {
  const inputProps = {
    target: target ?? null,
  };

  url = url.replace("internal:", "");

  const renderMedia = () => {
    if (videoUrl) {
      // If video URL is present, always render the video and ignore any images
      return (
        <div className="video-container">
          <iframe
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            src={`${videoUrl}?badge=0&autopause=0&player_id=0&app_id=58479&h=da46128a94`}
            title={title}
          ></iframe>
        </div>
      );
    }

    // Only render the image if there's no video
    let picture = (
      <picture>
        <source media="(min-width:768px)" srcSet={imgWebp} type="image/webp" />
        <img src={img} alt={title} />
      </picture>
    );

    if (imgSmall) {
      picture = (
        <picture>
          <source media="(min-width:768px)" srcSet={imgWebp} type="image/webp" />
          <source media="(min-width:768px)" srcSet={img} type="image/jpeg" />
          <source srcSet={imgSmallWebp} type="image/webp" />
          <img src={imgSmall} alt={title} />
        </picture>
      );
    }

    return picture;
  };

  const cardContent =
    url !== "" ? (
      url.includes("http") ? (
        <a target={"_blank"} rel={"noreferrer"} href={url} className={"link"}>
          {imgTitle && <div className="card-image-title">{imgTitle}</div>}
          {renderMedia()}
          {hasText && (
            <div className="content">
              <div className="content-title">{title}</div>
              <div
                className="content-description"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
              <div className="read-more">
                <div className="text">{linkLabel}</div>
                <div className="arrow-container">
                  <img src={RightArrow} alt="" />
                </div>
              </div>              
            </div>
          )}
        </a>
      ) : (
        <RouterLink to={url} {...inputProps} className={"link"}>
          {imgTitle && <div className="card-image-title">{imgTitle}</div>}
          {renderMedia()}
          {hasText && (
            <div className="content">
              <div
                className="content-title"
                dangerouslySetInnerHTML={{ __html: title }}
              ></div>
              <div
                className="content-description"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
              <div className="read-more">
                <div className="text">{linkLabel}</div>
                <div className="arrow-container">
                  <img src={RightArrow} alt="" />
                </div>
              </div>              
            </div>
          )}
        </RouterLink>
      )
    ) : (
      <div {...inputProps} className={"link"}>
        {imgTitle && <div className="card-image-title">{imgTitle}</div>}
        {renderMedia()}
        {hasText && (
          <div className="content">
            <div className="content-title">{title}</div>
            <div
              className="content-description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </div>
        )}
      </div>
    );

  return <div className="card">{cardContent}</div>;
};

export default Card;
