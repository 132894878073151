// PrivateRoute.tsx
import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { RestrictedPagePopup } from "../component/Popup/RestrictedPagePopup";
import { Restriction } from "../interfaces/Restriction";
import { authState } from "../store/Reducer";

interface PrivateRouteProps {
  path: string;
  exact?: boolean;
  children: React.ReactChildren | React.ReactChild;
  restriction?: Restriction;
  allowWithDocCheck?: boolean; // New prop to specify if the route should be accessible with DocCheck
}

function PrivateRoute({restriction, children, allowWithDocCheck = true, ...rest}: PrivateRouteProps) {
  const auth = useSelector(authState);
  const DocCheckAllowed = process.env.REACT_APP_DOC_CHECK_ALLOWED === 'true';

  const hasPermission = (restriction && auth?.roles.includes(restriction)) || (!restriction && auth.authenticated);
  
  // Check if the route should be accessible based on DocCheck status
  const isAccessible = allowWithDocCheck || !DocCheckAllowed;

  return (
    <Route {...rest}>
      {hasPermission && isAccessible ? children : <RestrictedPagePopup />}
    </Route>
  );
}

export default PrivateRoute;